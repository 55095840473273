@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
body {
  background-color: #fafbfc;
  font-family: 'Roboto', sans-serif;
}

::-moz-selection{
  background-color: #66ffb3;
  text-decoration: underline;
}

::selection{
  background-color: #66ffb3;
  text-decoration: underline;
}

h1{
  font-family: 'Roboto Condensed', sans-serif;
  color: #1f1f1f;
  font-size: 3rem;
}

h2{
  font-family: 'Roboto Condensed', sans-serif;
  color: #1f1f1f;
  font-size: 2.5rem;
}

.resize{
  width:1.3rem;
}

.main{
  margin: 10%;
}

.main h1{
  font-size: 4rem;
}

.main p{
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

span{
  color: #25A919;
}



.portfolio {
  margin: 0% 7% 7% 7%;
}

.tiny{
  color: #999999;
}

.main p.pron{
  font-size: 1rem;
  display: inline-block;
}

.media {
  width:1.6rem;
  height: auto;
}

.font {
  font-size: 1.1rem!important;
}
.navbar-light .navbar-brand {
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5rem;
}

.bg{
  background-color: #1f1f1f;;
}

a {
  color: white;
  -webkit-transition: 300ms;
  transition: 300ms;
}
a:hover {
  color: #66ffb3;
}

.navbar-light .navbar-brand{
  color: #37db89;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #37db89;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.navbar-toggler{
  outline: 0 !important;
}
.navbar-light .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(102, 255, 179, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-light .navbar-toggler{
  border-color: #66ffb3;
}

.btn{
  border-radius: 0;
  font-size: 1.3rem;
}

.btn-outline-success {
  background-color: rgb(47, 185, 116);
  border-color: #ffffff;
  color: #ffffff;
  margin-right: 1rem;
  margin-top: 1rem;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.btn-outline-success:hover{
  background-color: rgb(47, 185, 116);
  border-color: #ffffff;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.13);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.card-title.h5{
  color: #37db89;
  font-family: 'Roboto Condensed', sans-serif;
}

.card-deck{
  justify-content: center;
  margin: 0% 2.5%;
}

.card{
  border: none;
}

.card-text{
  font-family: 'Roboto', sans-serif;
}

.main .card-text{
  font-size: 1rem;
}

.card a{
  color: rgb(55, 219, 137);
}

.form-control {
  border-radius: 0;
  outline: 0 !important;
}

.form-control.active.focus,
.form-control.active:focus,
.form-control.focus,
.form-control.focus:active,
.form-control:active:focus,
.form-control:focus {
  outline: 0 !important;
  border: 1px solid #ced4da;
  box-shadow: none;
  outline-offset: 0  !important;
  background-image: none  !important;
  box-shadow: none  !important;
}

.col-md-6 .pad{
  padding-right: 10%;
}

.carousel-indicators{
  display: none;
}

.shadow{
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.5)!important;
}
